import store from '@/store'

export default [

  {
    path: '/matriculas',
    name: 'matriculas',
    component: () => import('@/views/Matriculas/Matriculas.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Matrículas',
      breadcrumb: [
        {
          text: 'Matrículas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/matriculas-create',
    name: 'matriculas-create',
    component: () => import('@/views/Matriculas/components/MatriculasCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      breadcrumb: [
        {
          text: 'Matrículas',
          to: '/matriculas',
        },
        {
          text: 'Formulario de creación de Matrícula',
          active: true,
        },
      ],
    },
  },
  {
    path: '/matriculas/update',
    name: 'matriculas-update',
    component: () => import('@/views/Matriculas/components/MatriculasUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar matrícula',
      breadcrumb: [
        {
          text: 'Matrículas',
          to: '/matriculas',
        },
        {
          text: 'Editar Matrícula',
          active: true,
        },
      ],
    },
  },
]