import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import axios from 'axios'

// Router, Store, and Main App
import router from './router'
import store from './store'
import App from './App.vue'

// Plugins
import '@/libs/portal-vue'
import '@/libs/acl'
import '@/libs/vue-select'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/tour'
import '@/@fake-db/db'

// Formateo Calendars (flatPickr)
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js';
flatpickr.localize(Spanish);

// Global Components
import './global-components'

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// Core Styles
require('@core/scss/core.scss')

// Assets Styles
require('@/assets/scss/style.scss')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Zoom Navegador
document.body.style.zoom = 0.9

// Mantener Sesion
require('@/store/:auth/subscriber')

// BaseURL API
console.log('process.env.VUE_APP_API_URL :', process.env)
axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.config.productionTip = true

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})