export default {
  generos: [
    {
      genero: 1,
      title: 'Masculino',
    },
    {
      genero: 2,
      title: 'Femenino',
    },
    {
      genero: 3,
      title: 'Otro',
    },
  ],
}
