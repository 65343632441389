export default {
  tiposIdentificaciones: [
    {
      value: 1,
      text: 'RUT',
    },
    {
      value: 2,
      text: 'IPE',
    }
  ],
}
