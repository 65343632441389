import axios from 'axios'

export default async function asistencias() {
  return 0
}



export async function fetchTipoAsistencia({ commit }) {
  try {
    const { data } = await axios.get('tipoasistencia')
    commit('setTipoAsistencia', data)
    return data
  } catch (e) {
    commit('asistenciaError', e.response.data)
  }
}

export async function fetchAsistencias({ commit }, params) {
  try {
    const {
      id_curso,
      fecha,
    } = params
    const { data } = await axios({
      method: 'GET',
      url: `asistenciabloque/${id_curso}/${fecha}`,
    })
    commit('setAsistencias', data)
    return data
  } catch (e) {
    commit('asistenciaError', e.response.data)
  }
}

export async function fetchAsistenciasMensual({ commit, dispatch }, datos) {
  try {
    const {
      id_curso,
      mes
    } = datos
    const { data } = await axios({
      method: 'GET',
      url: `asistencia/${id_curso}/${mes}`,
    })
    commit('setAsistenciasMensual', data)
  } catch (e) {
    commit('asistenciaError', e.response.data)
  }
}

export async function addAsistencia({ commit }, asistencia) {
  try {
    commit('asistenciaErrorNull')
    const response = await axios({
      method: 'POST',
      url: 'asistencia',
      data: {
        fecha: asistencia.fecha,
        id_curso: asistencia.id_curso,
        alumnos: asistencia.alumnos,
      },
    })
    commit('setFecha_curso', response.data)
    commit('setResponse', response)
  } catch (e) {
    commit('asistenciaError', e.response.data)
  }
}

export async function updateAsistencia({ commit }, asistencia) {
  try {
    commit('asistenciaErrorNull')
    const response  = await axios({
      method: 'PUT',
      url: `asistencia/${asistencia.id_asistencia}`,
      data: {
        tipo_asistencia: asistencia.tipo_asistencia,
      },
    })
    commit('setResponse', response)
  } catch (e) {
    commit('asistenciaError', e.response.data)
  }
}

export async function removeAsistencias({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/asistencias/${id}`,
    })
    // actualizamos lista de asistencias
    dispatch('fetchAsistencias')
  } catch (e) {
    commit('asistenciaError', e.response.data)
  }
}
