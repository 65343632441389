import store from '@/store'

export default [
  {
    path: '/ver-libros',
    name: 'ver-libros',
    component: () => import('@/views/MisLibros/VerLibros.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Ver Libros',
      breadcrumb: [
        {
          text: 'Ver Libros',
          active: true,
        },
      ],
    },
  },

  // Finalmente será como el update
  // {
  //   path: '/ver-libros/:id/libro',
  //   name: 'libro',
  //   component: () => import('@/views/MisLibros/VerLibros.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'login',
  //       })
  //     }
  //     return next()
  //   },
  //   meta: {
  //     pageTitle: '',
  //     configs: false,
  //     breadcrumb: [
  //       {
  //         text: 'Mis Libros',
  //         to: '/mis-libros',
  //       },
  //       {
  //         text: 'Libro',
  //         active: true,
  //         configs: true,
  //         optionsConfigs: [
  //           {
  //             to: 'config-curso',
  //             txtBtn: 'Configurar Curso',
  //             icon: 'ToolIcon',
  //           },
  //           {
  //             to: 'config-asignaturas',
  //             txtBtn: 'Configurar Asignaturas',
  //             icon: 'ToolIcon',
  //           },
  //           {
  //             to: 'config-promedios',
  //             txtBtn: 'Configurar Promedios',
  //             icon: 'ToolIcon',
  //           },
  //         ]
  //       },
  //     ],
  //   },
  // },
]